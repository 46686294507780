import './_bootstrap'

import header from './alpine/_header'


import.meta.glob([
  '../images/**'
])
import '../css/app.css'

window.Alpine.store('header', header)
 
window.Alpine.start()