/** @type {import('tailwindcss').Config} */

const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/**/*.{php,vue,js}",
    "!./resources/blocks/*.js",
    "./config/acf.php"
  ],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '2rem',
        md: '4rem',
        lg: '8rem',
        '2xl': '12rem'
      },
      screens: {
        sm: '2160px'
      }
    },
    fontFamily: {
      title: ['jaf-mashine', 'sans-serif'],
      body: ['rajdhani', 'sans-serif']
    },
    colors: {
      blue: {
        DEFAULT: '#1B345A',
        light: '#294A78',
        pale: '#DDE4E8'
      },
      red: '#ED1C29',
      white: '#FFFFFF',
      black: '#000000',
      current: 'currentColor',
      transparent: 'transparent'
    },
    extend: {
      boxShadow: {
        tip: '0px 0px 15px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color)'
      },
      fontSize: {
        '3xl': ['1.75rem', {
          lineHeight: '2.25rem'
        }],
        '8xl': ['5rem', {
          lineHeight: 1
        }]
      },
      letterSpacing: {
        title: '-0.08em'
      },
      backgroundImage: ({ theme }) => ({
        main: 'url(@/assets/images/bg.png)',
        mobile: 'url(@/assets/images/mobile-bg.png)',
        faq: 'url(@/assets/images/faq-bg.png)',
        'set-image': 'var(--set-image)',
        'split-bg': 'linear-gradient(to bottom, ' + theme('colors.red') + ' 0%, ' + theme('colors.red') + ' 50%, ' + theme('colors.blue.DEFAULT') + ' 50%, ' + theme('colors.blue.DEFAULT') + ' 100%)'
      })
    },
  },
  plugins: [
    plugin(function({ addVariant }) {
      addVariant('inner', [
        '& > .block-editor-inner-blocks > .block-editor-block-list__layout',
        '& > .block-editor-block-list__layout'
      ]);
      addVariant('acf-inner', '& > .block-editor-block-list__layout')
      addVariant('inner-block', [
        '& > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-editor-block-list__block',
        '& > .block-editor-block-list__layout > .block-editor-block-list__block'
      ]);
      addVariant('acf-inner-block', '& > .block-editor-block-list__layout > .block-editor-block-list__block');
      addVariant('appender', '& > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-list-appender');
    }),
  ],
};
